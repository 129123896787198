import React, { useState, createContext } from 'react';

import CustomizedSnackbars from '../../components/alert/Alert';

const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
  const [novoAlerta, setNovoAlerta] = useState(null);

  const notification = (tipo, msg) => {
    const novoAlerta = {
      type: tipo,
      text: msg,
    };

    setNovoAlerta(novoAlerta);
    setTimeout(() => setNovoAlerta(false), 3000);
  };

  return (
    <AppContext.Provider value={{ notification }}>
      {novoAlerta && (
        <CustomizedSnackbars
          texto={novoAlerta.text}
          tipo={novoAlerta.type}
        ></CustomizedSnackbars>
      )}
      {children}
    </AppContext.Provider>
  );
};

export { AppContextProvider };

export default AppContext;
