import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Login from './pages/login/Login';

import App from './pages/app';
import RecuperarSenha from './pages/recuperarSenha/RecuperarSenha';
import CriarConta from './pages/criarConta/CriarConta';

import { isSignedIn } from './auth/index';

import { ConfigProvider } from './auth/context';

const PrivateRoute = ({ component: Component, signed, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      signed ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location.pathname } }}
        />
      )
    }
  />
);

const Routes = () => {
  const [userData, setUserData] = useState(undefined);
  const [signed, setSigned] = useState(false);
  const [signLoaded, setSignLoaded] = useState(false);

  useEffect(() => {
    validarSessao();
  }, []);

  const validarSessao = (atualizarUsuarioContext) => {
    const sessionData = isSignedIn();
    console.log('sessionData', sessionData);
    setSigned(sessionData.signed);
    setUserData(sessionData.userData);
    setSignLoaded(true);

    if (atualizarUsuarioContext) atualizarUsuarioContext(sessionData.userData);
  };
  console.log('signLoaded', signLoaded);
  if (!signLoaded) return null;
  console.log('signed', signed);

  return (
    <ConfigProvider userData={signed ? userData : {}}>
      <Router basename="/unidunete">
        <Switch>
          <Route
            path="/login"
            exact={true}
            render={(props) => (
              <Login {...props} validarSessao={validarSessao} />
            )}
          />
          <Route path="/criarConta/:param?" component={CriarConta} />

          <Route path="/recuperar-senha" component={RecuperarSenha} />

          <Redirect exact from={`/`} to={`/app`} />

          <PrivateRoute signed={signed} path="/app" component={App} />

          <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
      </Router>
    </ConfigProvider>
  );
};

export default Routes;
