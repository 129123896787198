import React from 'react';
import Stack from '@material-ui/core/Stack';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/core/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars({ texto, tipo }) {
  return (
    <Stack spacing={2} sx={{ width: '50%' }} style={{ margin: 'auto' }}>
      <Snackbar open={true} autoHideDuration={3000}>
        <Alert severity={tipo} sx={{ width: '100%' }}>
          {texto}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

// tipos de alert = "error", "warning", "info", "success"

/* <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert> */
