import React, { useState, useContext } from 'react';

import { Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import AuthContext from '../../auth/context';

import Header from '../../components/header/Header';
import MenuSide from '../../components/menu/MenuSide';

import Leitor from '../../routes/leitor/Leitor';

import Arquivos from '../../routes/arquivos/Arquivos';

import { AppContextProvider } from './context';

// let largura = window.screen.width;

export default function App({ match, history, location }) {
  const [open, setOpen] = useState(false);
  const { usuario } = useContext(AuthContext);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <AppContextProvider>
      <div style={{ background: '#e8e8e8' }}>
        <CssBaseline />
        <Header abrir={open} userName={usuario} />
        <MenuSide closeMenu={handleDrawerClose} abrir={open} />
      </div>
      <Main open={open}>
        <Switch>
          <Route path={`${match.url}/`} exact={true} component={Arquivos} />
          <Route path={`${match.url}/view/:materialId`} component={Leitor} />
        </Switch>
      </Main>
    </AppContextProvider>
  );
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    background: '#e8e8e8',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 250,
    }),
  })
);
