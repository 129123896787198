import jwtDecode from 'jwt-decode';

const TOKEN_KEY = '@unidunete-Token';

export const onSignIn = (token) => localStorage.setItem(TOKEN_KEY, token);

export const onSignOut = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isSignedIn = () => {
  const token = localStorage.getItem(TOKEN_KEY);

  const signed = token !== null;

  let userData = {};

  if (signed) {
    userData = getUserData();

    // alert(JSON.stringify(userData));
  }
  return { signed, userData };
};

export const getUserData = () => {
  const token = localStorage.getItem(TOKEN_KEY);

  const userData = jwtDecode(token);

  return userData;
};
