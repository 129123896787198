import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Sidebar = styled.div`
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 15px;
  color: #424242;

  &:hover {
    border-left: 4px solid #424242;
    cursor: pointer;
  }
`;

export const SidebarLabel = styled.span`
  margin-left: 16px;
`;

export const DropdownLink = styled(Link)`
  background: #eaeaea;
  height: 60px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #424242;
  font-size: 15px;

  &:hover {
    background: rgb(63, 81, 181, 0.2);
    cursor: pointer;
  }
`;
