import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
import VpnKey from '@material-ui/icons/VpnKey';
import LockIcon from '@mui/icons-material/Lock';
// import { NotificationManager } from 'react-notifications';
import CustomizedSnackbars from '../../components/alert/Alert';
import api from '../../services/api';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { validarCPF } from '../../helpers';
import { Box1, BoxImg, Container } from './stylesConta';
import { Button, Grid, Paper } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import logo from '../../images/perto-logo.png';

const useStyles = makeStyles();

let text = '';
let tipo = '';

export default function SignUpPage({ history, location }) {
  const classes = useStyles();

  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmacaoSenha, setConfirmacaoSenha] = useState('');
  const [chave, setChave] = useState('');
  const [alerta, setAlerta] = useState(false);

  const cadastrar = (e) => {
    e.preventDefault();

    let isOk = true;

    if (nome.length < 3) {
      isOk = false;

      text = 'Nome inválido';
      tipo = 'warning';
      alertMsg();
    }

    if (!validarCPF(cpf)) {
      isOk = false;
      text = 'CPF inválido';
      tipo = 'warning';
      alertMsg();
    }

    if (email === '') {
      isOk = false;
      text = 'Informe seu email';
      tipo = 'warning';
      alertMsg();
    }

    if (chave.length < 6) {
      isOk = false;
      text = 'Chave de acesso inválida';
      tipo = 'warning';
      alertMsg();
    }

    if (senha.length < 3) {
      isOk = false;
      text = 'Senha inválida';
      tipo = 'warning';
      alertMsg();
    } else {
      if (senha !== confirmacaoSenha) {
        isOk = false;
        text = 'A confirmação não confere com a senha.';
        tipo = 'warning';
        alertMsg();
      }
    }

    if (!isOk) return;

    api
      .post('usuarios/cadastrarAgrupar', {
        nome,
        cpf,
        email,
        senha,
        grupoIdentificador: 'UNIDUNETE',
      })
      .then((res) => {
        if (res.id > 0) {
          text = 'Cadastro realizado com sucesso!';
          tipo = 'success';
          alertMsg();
          return history.push(`/login`);
        }

        if (res.codes.includes(407)) {
          text = 'Já existe um cadastro com o CPF informado.';
          tipo = 'warning';
          alertMsg();
          return;
        }
        return (
          (text = 'Não foi possível efetuar o cadastro. Verifique os dados.'),
          (tipo = 'error'),
          alertMsg()
        );
      })
      .catch((e) => console.log('Erro: ' + e));
  };

  const alertMsg = () => {
    setAlerta(true);
    setTimeout(() => setAlerta(false), 3000);
  };

  return (
    <Container>
      <Box1>
        <Paper
          sx={{
            p: 2,
            margin: 'auto',
            maxWidth: 500,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <BoxImg>
            <img src={logo} alt="logo" />
            <h4>Uni Dunê Te</h4>
          </BoxImg>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid style={{ textAlign: 'center' }} item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    <h1>Crie sua conta</h1>
                  </Typography>
                  <h5 style={{ textAlign: 'center' }}>
                    Já tem uma conta no app Perto Teachers? Apenas faça{' '}
                    <a href="login">login</a>!{' '}
                  </h5>
                  <form className={classes.form} onSubmit={cadastrar}>
                    <br />

                    <TextField
                      fullWidth
                      id="input-with-icon-textfield"
                      label="Nome"
                      // type="password"
                      onChange={(e) => setNome(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Face />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    <br />

                    <br />

                    <TextField
                      fullWidth
                      id="input-with-icon-textfield"
                      label="CPF (Apenas números)"
                      // type="password"
                      onChange={(e) => setCpf(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    <br />

                    <br />

                    <TextField
                      fullWidth
                      id="input-with-icon-textfield"
                      label="Email"
                      // type="password"
                      onChange={(e) => setEmail(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    <br />

                    <br />

                    <TextField
                      fullWidth
                      id="input-with-icon-textfield"
                      label="Senha"
                      type="password"
                      onChange={(e) => setSenha(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    <br />

                    <br />

                    <TextField
                      fullWidth
                      id="input-with-icon-textfield"
                      label="Confirme sua senha"
                      type="password"
                      onChange={(e) => setConfirmacaoSenha(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    <br />

                    <br />
                    <TextField
                      fullWidth
                      id="input-with-icon-textfield"
                      label="Chave de acesso"
                      onChange={(e) => setChave(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <VpnKey />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />

                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      style={{ marginTop: 15 }}
                      justifyContent="center"
                    >
                      <Grid item xs={6}>
                        <Button
                          round
                          variant="contained"
                          color="secondary"
                          type="submit"
                        >
                          Cadastrar
                        </Button>
                      </Grid>

                      <Grid item xs={6}>
                        <Button
                          round
                          variant="contained"
                          color="secondary"
                          onClick={() => history.push(`/login`)}
                        >
                          Voltar ao login
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                  {alerta && (
                    <CustomizedSnackbars
                      texto={text}
                      tipo={tipo}
                    ></CustomizedSnackbars>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box1>
    </Container>
  );
}
