import React, { useState, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import Grid from '@material-ui/core/Grid';
import api from '../../services/api';
import serverHost from '../../services/serverHost';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
// import Button from "components/CustomButtons/Button.js";
import InputAdornment from '@material-ui/core/InputAdornment';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
// import { MicNoneSharp } from '@material-ui/icons';
import { Box, Button } from '@material-ui/core';
import { Pagina } from './stylesLeitor';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

let pos = { top: 0, left: 0, x: 0, y: 0 };

let movendo = false;

const mouseDownHandler = function (e) {
  const ele = document.getElementById('leitor');

  movendo = true;

  pos = {
    // The current scroll
    left: ele.scrollLeft,
    top: ele.scrollTop,
    // Get the current mouse position
    x: e.clientX,
    y: e.clientY,
  };
};

const mouseMoveHandler = function (e) {
  if (!movendo) return;

  const ele = document.getElementById('leitor');

  // How far the mouse has been moved
  const dx = e.clientX - pos.x;
  const dy = e.clientY - pos.y;

  // Scroll the element
  ele.scrollTop = pos.top - dy;
  ele.scrollLeft = pos.left - dx;
};

const mouseUpHandler = function () {
  return (movendo = false);
};

const Leitor = ({ match }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginaIr, setPaginaIr] = useState('');
  const [material, setMaterial] = useState({});
  // const [materialNome] = useState(match.params.material);
  const [materialId] = useState(match.params.materialId);
  const [progresso, setProgresso] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [duasPaginas, setDuasPaginas] = useState(false);
  const [escala, setEscala] = useState(1);

  const doc = useRef(null);

  useEffect(() => {
    api
      .post('materiais/carregar', { id: materialId })
      .then((material) => {
        if (material.nome.toUpperCase().includes('PROFESSOR'))
          setWindowHeight(1200);
        // if(material.nome.includes('Revistinha') || material.nome.includes('Poster')) {
        //   setWindowHeight(1800);
        //   setWindowWidth(2700);
        // }
        // console.log('material', material);
        setMaterial(material);
      })
      .catch((e) => console.log(e));
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    scrollToRef(doc);
  };

  const arquivoUri = `${serverHost()}/${material.caminhoArquivo}`;

  if (!material.caminhoArquivo) return null;

  return (
    <div>
      <Grid container style={{ marginBottom: 15 }}>
        <Grid item xs={12}>
          <h2>{material.nome}</h2>
        </Grid>
      </Grid>
      <Grid
        item
        md={9}
        xs={12}
        style={{
          backgroundColor: 'white',
          padding: 2,
          margin: 'auto',
          marginBottom: 15,
        }}
      >
        {progresso >= 100 ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container style={{ justifyContent: 'center' }}>
              <div style={{ marginRight: '10%' }}>
                <Button
                  style={{ marginRight: 5 }}
                  variant="contained"
                  justIcon
                  size="lg"
                  color="secondary"
                  onClick={() => {
                    if (escala > 0.5) setEscala(escala - 0.5);
                  }}
                >
                  <ZoomOut />
                </Button>
                <Button
                  variant="contained"
                  justIcon
                  size="lg"
                  color="secondary"
                  onClick={() => {
                    if (escala < 6) setEscala(escala + 0.5);
                  }}
                >
                  <ZoomIn />
                </Button>
              </div>
              <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                <ControlePaginas
                  pageNumber={pageNumber}
                  duasPaginas={duasPaginas}
                  numPages={numPages}
                  setPageNumber={setPageNumber}
                  paginaIr={paginaIr}
                  setPaginaIr={setPaginaIr}
                  doc={doc}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <div className="caixa-carregamento">
            <h6>Carregando documento... ({progresso}%)</h6>
            <br />
            <CircularProgress />
          </div>
        )}
      </Grid>
      <div
        id="leitor"
        style={
          progresso < 100
            ? { display: 'none' }
            : {
                overflow: 'auto',
                cursor: 'grab',
                userSelect: 'none',
                minHeight: windowHeight,
              }
        }
        onMouseDown={mouseDownHandler}
        onMouseUp={mouseUpHandler}
        onMouseMove={mouseMoveHandler}
        onMouseLeave={mouseUpHandler}
        ref={doc}
      >
        <Pagina>
          <Document
            loading={
              <div style={{ paddingLeft: 120, paddingRight: 120 }}>
                <div className="caixa-carregamento">
                  <h6>Carregando documento... ({progresso}%)</h6>
                  <br />
                  <CircularProgress />
                </div>
              </div>
            }
            file={arquivoUri}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadProgress={({ loaded, total }) => {
              const pct = Math.floor((loaded / total) * 100);
              if (pct > progresso) setProgresso(pct);
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-around"
            >
              <Grid item xs={duasPaginas ? 6 : 12}>
                <Page
                  // width={windowWidth}
                  height={windowHeight}
                  scale={escala}
                  pageNumber={pageNumber}
                />
              </Grid>
              {duasPaginas && numPages >= pageNumber + 1 && pageNumber > 1 && (
                <Grid item xs={6}>
                  <Page height={windowHeight} pageNumber={pageNumber + 1} />
                </Grid>
              )}
            </Grid>
          </Document>
        </Pagina>
      </div>

      <Grid
        item
        md={9}
        xs={12}
        style={{
          backgroundColor: 'white',
          padding: 2,
          margin: 'auto',
          marginTop: 15,
        }}
      >
        {progresso >= 100 && (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container style={{ justifyContent: 'center' }}>
              <div style={{ marginRight: '10%' }}>
                <Button
                  style={{ marginRight: 5 }}
                  variant="contained"
                  justIcon
                  size="lg"
                  color="secondary"
                  onClick={() => {
                    if (escala > 0.5) setEscala(escala - 0.5);
                  }}
                >
                  <ZoomOut />
                </Button>
                <Button
                  variant="contained"
                  justIcon
                  size="lg"
                  color="secondary"
                  onClick={() => {
                    if (escala < 6) setEscala(escala + 0.5);
                  }}
                >
                  <ZoomIn />
                </Button>
              </div>
              <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
                <ControlePaginas
                  pageNumber={pageNumber}
                  duasPaginas={duasPaginas}
                  numPages={numPages}
                  setPageNumber={setPageNumber}
                  paginaIr={paginaIr}
                  setPaginaIr={setPaginaIr}
                  doc={doc}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </div>
  );
};

export default Leitor;

const ControlePaginas = ({
  pageNumber,
  duasPaginas,
  numPages,
  setPageNumber,
  paginaIr,
  setPaginaIr,
}) => (
  <Grid container>
    <Grid item md={2} xs={3} alignItems="center">
      <Button
        variant="contained"
        justIcon
        size="lg"
        color="secondary"
        onClick={() => {
          const qtdPagsVoltar = duasPaginas ? 2 : 1;

          if (pageNumber - qtdPagsVoltar > 0)
            return setPageNumber(pageNumber - qtdPagsVoltar);

          if (pageNumber - 1 > 0) setPageNumber(pageNumber - 1);
        }}
      >
        <ArrowBack />
      </Button>
    </Grid>
    <Grid item md={4} xs={6}>
      <Grid container direction="row">
        <Grid item md={12} xs={12}>
          {duasPaginas && numPages >= pageNumber + 1 && pageNumber > 1 ? (
            <h5>
              Páginas <b>{pageNumber}</b> e <b>{pageNumber + 1}</b> de{' '}
              <b>{numPages}</b>
            </h5>
          ) : (
            <h5>
              Página <b>{pageNumber}</b> de <b>{numPages}</b>
            </h5>
          )}
        </Grid>
        <Grid item md={12} xs={12}>
          <Input
            id="standard-adornment-password"
            type="text"
            placeholder="Ir para a página"
            value={paginaIr}
            onChange={(e) => setPaginaIr(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (Number(paginaIr) > 0 && Number(paginaIr) <= numPages)
                  setPageNumber(Number(paginaIr));
                setPaginaIr('');
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    if (Number(paginaIr) > 0 && Number(paginaIr) <= numPages)
                      setPageNumber(Number(paginaIr));
                    setPaginaIr('');
                  }}
                >
                  <PlayCircleFilled />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item md={2} xs={3}>
      <Button
        variant="contained"
        justIcon
        size="lg"
        color="secondary"
        onClick={() => {
          const qtdPagsAvancar = duasPaginas && pageNumber > 1 ? 2 : 1;

          if (pageNumber + qtdPagsAvancar <= numPages)
            return setPageNumber(pageNumber + qtdPagsAvancar);

          if (pageNumber + 1 <= numPages) return setPageNumber(pageNumber + 1);
        }}
      >
        <ArrowForward />
      </Button>
    </Grid>
  </Grid>
);
