import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
// import Button from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';
// import { NotificationManager } from 'react-notifications';
// import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
// import image from 'assets/img/bg7.jpg';
import api from '../../services/api';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { validarCPF } from '../../helpers';
import { Paper } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Box1, BoxImg, Container } from './stylesRecuperar';
import { Button } from '@material-ui/core';
import CustomizedSnackbars from '../../components/alert/Alert';
import logo from '../../images/perto-logo.png';

const useStyles = makeStyles();
let text = '';
let tipo = '';

export default function RecuperarSenha({ history, location }) {
  const classes = useStyles();

  const [fim, setFim] = useState(false);
  const [cpf, setCpf] = useState('');
  const [alerta, setAlerta] = useState(false);

  const cadastrar = (e) => {
    e.preventDefault();

    if (!validarCPF(cpf)) {
      text = 'CPF inválido';
      tipo = 'warning';
      alertMsg();
      return;
    }

    const params = {
      cpf,
      naoEnviarEmail: false,
    };

    api
      .post('usuarios/solicitarRedSenha', params)
      .then((res) => {
        const { status, email } = res;

        if (status === 200) {
          setFim(true);
          text = `Um link de redefinição foi enviado para ${email}.`;
          tipo = 'success';
          alertMsg();
          return;
        }

        if (status === 401) {
          text = 'Não foi localizado cadastro com o CPF informado.';
          tipo = 'warning';
          alertMsg();
          return;
        }

        if (status === 402) {
          text = 'O cadastro do CPF informado não possui um email válido.';
          tipo = 'warning';
          alertMsg();
          return;
        }
      })
      .catch((e) => console.log('Erro: ' + e));
  };

  const alertMsg = () => {
    setAlerta(true);
    setTimeout(() => setAlerta(false), 3000);
  };

  return (
    <Container>
      <Box1>
        <Paper
          sx={{
            p: 2,
            margin: 'auto',
            maxWidth: 500,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <BoxImg>
            <img src={logo} alt="logo" />
            <h4>Uni Dunê Te</h4>
          </BoxImg>
          <Grid container spacing={2}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid style={{ textAlign: 'center' }} item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    <h1>Recuperar senha</h1>
                  </Typography>
                  <br />
                  <form className={classes.form} onSubmit={cadastrar}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses,
                      }}
                      inputProps={{
                        onChange: (e) => setCpf(e.target.value),
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <AccountCircleIcon
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        placeholder: 'CPF (Apenas números)',
                      }}
                    />

                    {!fim && (
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        style={{ marginTop: 15 }}
                      >
                        <Grid item xs={6}>
                          <Button
                            round
                            variant="contained"
                            color="secondary"
                            type="submit"
                          >
                            Recuperar
                          </Button>
                        </Grid>

                        <Grid item xs={6}>
                          <Button
                            round
                            variant="contained"
                            color="secondary"
                            onClick={() => history.push(`/login`)}
                          >
                            Voltar ao login
                          </Button>
                        </Grid>
                      </Grid>
                    )}

                    {fim && (
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        style={{ marginTop: 15 }}
                        justifyContent="center"
                      >
                        <Grid item xs={6}>
                          <Button
                            round
                            variant="contained"
                            color="secondary"
                            onClick={() => history.push(`/login`)}
                          >
                            Voltar ao login
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    {alerta && (
                      <CustomizedSnackbars
                        texto={text}
                        tipo={tipo}
                      ></CustomizedSnackbars>
                    )}
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box1>
    </Container>
  );
}
