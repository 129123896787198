import React from 'react';
// import { logout } from '../../services/auth';
import { onSignOut } from '../../auth/index';

import { useHistory } from 'react-router-dom';

import { alpha, styled } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Button, Menu, MenuItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { MenuBook } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { Grid } from '@material-ui/core';

export default function Header({ openMenu, abrir, userName }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openButton = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleDrawerOpen = () => {
  //   openMenu();
  // };

  const sair = () => {
    onSignOut();
    // history.push('/');
    history.go(0);
  };

  return (
    <AppBar position="fixed" open={abrir}>
      <Toolbar style={{ background: '#952cae' }}>
        {/* <IconButton
          color="inherit"
          aria-label="open drawer"
          // onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(abrir && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton> */}

        <span style={{ fontWeight: 'bolder' }}>Uni Dunê Te</span>

        <Box sx={{ flexGrow: 1 }}>
          <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Button
                  title="Lista de materiais"
                  color="inherit"
                  onClick={() => history.push('/app')}
                >
                  <MenuBook />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Button
          id="demo-customized-button"
          aria-controls="demo-customized-menu"
          aria-haspopup="true"
          aria-expanded={openButton ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          style={{ marginLeft: 'auto', background: '#952cae' }}
          startIcon={<AccountCircleIcon />}
        >
          {userName.nome}
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={openButton}
          onClose={handleClose}
        >
          <MenuItem onClick={sair} disableRipple>
            Sair
          </MenuItem>
        </StyledMenu>
      </Toolbar>
    </AppBar>
  );
}
const drawerWidth = 240;

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
