import React from 'react';
import { isMobile } from 'react-device-detect';

import Drawer from '@material-ui/core/Drawer';
import { styled, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import SubMenu from '../sidebar/SubMenu';
import { SidebarData } from '../sidebar/SidebarData';
import Logo from '../../images/perto-logo.png';

let largura = window.screen.width;

export default function MenuSide({ closeMenu, abrir }) {
  const theme = useTheme();

  const handleDrawerClose = () => {
    closeMenu();
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isMobile ? mobileWidth : drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={abrir}
      >
        <DrawerHeader
          style={{ justifyContent: 'space-between', background: '#952cae' }}
        >
          <img
            src={Logo}
            alt=""
            style={{ height: 50, width: 100, margin: 'auto' }}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          {SidebarData.map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}
        </List>
      </Drawer>
      <Main open={abrir}>
        <DrawerHeader />
      </Main>
    </>
  );
}
const drawerWidth = 240;
const mobileWidth = largura;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);
