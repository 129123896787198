import React, { useState, useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import CustomizedSnackbars from '../../components/alert/Alert';
// import { onSignIn } from '../../auth/index';
import AuthContext from '../../auth/context';
import logo from '../../images/perto-logo.png';
import api from '../../services/api';
import { login } from '../../services/auth';
import { getQueryParams } from '../../helpers';

import TextField from '@material-ui/core/TextField';
import Stack from '@material-ui/core/Stack';
import Button from '@material-ui/core/Button';
import { Box1, Container, BoxForm, Form, BoxImg } from './stylesLogin';
import { Grid } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';

// import { login } from '../../services/auth';
let text = '';
let tipo = '';

export default function Login({ location, validarSessao }) {
  const { atualizarUsuario } = useContext(AuthContext);
  const [alerta, setAlerta] = useState(false);
  const [CPF, setCPF] = useState('');
  const [senha, setSenha] = useState('');
  const history = useHistory();
  const [queryParams] = useState(getQueryParams(location.search));

  useEffect(() => {
    if (queryParams['m'] && queryParams['m'] === 'confirm')
      console.log(
        'Para concluir seu cadastro, acesse o link de confirmação enviado para seu email.'
      );
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!CPF || !senha) {
      text = 'Preencha CPF e Senha para acesso';
      tipo = 'warning';
      alertMsg();
    } else {
      console.log('else');

      api
        .post('usuarios/autenticarGrupo', { CPF, senha, grupo: 'UNIDUNITE' })
        .then((res) => {
          if (res.isOk) {
            login(res.token);
            validarSessao(atualizarUsuario);

            return history.push(queryParams['f'] ? queryParams['f'] : '/app');
          }

          text = 'Email/Senha errado!';
          tipo = 'warning';
          alertMsg();
        })
        .catch((e) => console.log('Erro: ' + e));
    }
  };

  const alertMsg = () => {
    setAlerta(true);
    setTimeout(() => setAlerta(false), 3000);
  };

  return (
    <Container>
      <Box1>
        <BoxForm
          style={{
            width: isMobile ? '100%' : '450px',
          }}
        >
          <Form onSubmit={handleSubmit}>
            <BoxImg>
              <img src={logo} alt="logo" />
              <h4>Uni Dunê Te</h4>
            </BoxImg>
            <Stack spacing={1} sx={{ width: '100%' }}>
              <TextField
                style={{ margin: '30px', background: '#FFFF' }}
                id="login"
                label="Seu CPF (apenas números)"
                type="number"
                variant="filled"
                onChange={(event) => setCPF(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                style={{ margin: '30px', background: '#FFFF' }}
                id="senha"
                label="Senha"
                type="password"
                variant="filled"
                onChange={(event) => setSenha(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <div>
                <Button
                  style={{ margin: '30px', background: '#952cae' }}
                  type="submit"
                  variant="contained"
                >
                  Entrar
                </Button>
                <Button
                  style={{ margin: '30px', background: '#952cae' }}
                  onClick={() => history.push(`/criarConta`)}
                  variant="contained"
                >
                  Criar conta
                </Button>
              </div>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item style={{ marginRight: 15 }}>
                  <h4
                    style={{
                      fontSize: '1.125rem',
                      lineHeight: '1.5em',
                      marginBottom: 10,
                    }}
                  >
                    <a
                      style={{ color: '#9c27b0', textDecoration: 'none' }}
                      href="/unidunete/recuperar-senha"
                    >
                      Esqueci minha senha
                    </a>
                  </h4>
                </Grid>
              </Grid>
            </Stack>
            {alerta && (
              <CustomizedSnackbars
                texto={text}
                tipo={tipo}
              ></CustomizedSnackbars>
            )}
          </Form>
        </BoxForm>
      </Box1>
    </Container>
  );
}
