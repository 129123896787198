import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  // {
  // title: 'Acesso Digital',
  // // path: '/acesso',
  // icon: <AiIcons.AiFillHome />,
  // iconClosed: <RiIcons.RiArrowDownSFill />,
  // iconOpened: <RiIcons.RiArrowUpSFill />,
  // subNav: [
  //   {
  //     title: 'Materiais Bilíngue',
  //     path: '/app/materiais',
  //     icon: <IoIcons.IoIosPaper />,
  //   },
  // ],
  // },
  // {
  //   title: 'Financeiro',
  //   path: '/financeiro',
  //   icon: <IoIcons.IoIosPaper />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: 'Relatórios de Pagamentos',
  //       path: '/app/relatorio-pagamentos',
  //       icon: <IoIcons.IoIosPaper />,
  //       cName: 'sub-nav',
  //     },
  //     {
  //       title: 'Boletos/faturas (painel de controle)',
  //       path: '/app/boletos',
  //       icon: <IoIcons.IoIosPaper />,
  //       cName: 'sub-nav',
  //     },
  //   ],
  // },
  // {
  //   title: 'Pedidos',
  //   path: '/messages',
  //   icon: <FaIcons.FaEnvelopeOpenText />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: 'Catalogo',
  //       path: '/app/catalogo',
  //       icon: <IoIcons.IoIosPaper />,
  //     },
  //     {
  //       title: 'Meu carrinho',
  //       path: '/app/carrinho',
  //       icon: <IoIcons.IoIosPaper />,
  //     },
  //     {
  //       title: 'Pedidos da escola',
  //       path: '/app/pedidos-escola',
  //       icon: <IoIcons.IoIosPaper />,
  //     },
  //   ],
  // },
];
