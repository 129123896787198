import styled from 'styled-components';

import Box from '@material-ui/core/Box';
// import Container from '@material-ui/core/Container';

export const Container = styled.div`
  height: 100vh;
  align-items: center;
  display: flex;
`;

export const Box1 = styled(Box)`
  align-items: center;
  display: flex;
  width: 100%;
  height: 80%;
`;

export const BoxForm = styled.div`
  margin: auto;
  /* width: isMobile ? 100% : 450px; */
  text-align: center;
  display: flex;
`;

export const Form = styled.form`
  text-align: center;
  background: #ffff;
  margin: auto;
  width: 90%;
  color: white;
  border-radius: 10px;
`;

export const BoxImg = styled.div`
  width: auto;
  padding: 20px;
  background: #952cae;
  margin-top: -40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 15px;
  border-radius: 10px;

  img {
    height: 50px;
  }
`;
