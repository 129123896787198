import React, { Fragment, useEffect, useState } from 'react';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import Table from '../../components/Table/Table.js';
// import Button from "components/CustomButtons/Button.js";
import api from '../../services/api';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getQueryParams } from '../../helpers';
import { Button } from '@material-ui/core';

let materiaisGeral = [];
let niveisGeral = [];
let categoriasGeral = [];
let subcategoriasGeral = [];
let filtroNivel = 0;
let filtroCategoria = 0;
let filtroSubcategoria = 0;

export default function Material({ history, location }) {
  const [carregando, setCarregando] = useState(false);
  const [tabelaMateriais, setTabelaMateriais] = useState({});
  const [tabelasFiltros, setTabelasFiltros] = useState([]);

  useEffect(() => {
    setCarregando(true);

    api
      .post('materiais/listar', { produto: 2 })
      .then((materiais) => {
        materiaisGeral = materiais;
        configurarFiltros(materiais);
        montarTabela();
      })
      .catch((e) => console.log(e));
  }, []);

  const configurarFiltros = (materiais) => {
    const parametros = getQueryParams(location.search);

    materiais.forEach((m) => {
      if (
        m.udtNivelId &&
        niveisGeral.filter((cg) => cg.id === m.udtNivelId).length === 0
      ) {
        niveisGeral.push({ id: m.udtNivelId, nome: m.udtNivelNome });
      }

      if (
        m.categoriaId &&
        categoriasGeral.filter(
          (cg) => cg.id === m.categoriaId && cg.nivelId === m.udtNivelId
        ).length === 0
      ) {
        categoriasGeral.push({
          id: m.categoriaId,
          nome: m.categoriaNome,
          nivelId: m.udtNivelId,
        });
      }

      if (
        m.subcategoriaId &&
        subcategoriasGeral.filter(
          (sg) => sg.id === m.subcategoriaId && sg.categoriaId === m.categoriaId
        ).length === 0
      ) {
        subcategoriasGeral.push({
          id: m.subcategoriaId,
          nome: m.subcategoriaNome,
          categoriaId: m.categoriaId,
        });
      }
    });

    if (parametros.niv) filtroNivel = Number(parametros.niv);
    if (parametros.cat) filtroCategoria = Number(parametros.cat);
    if (parametros.sub) filtroSubcategoria = Number(parametros.sub);
  };

  const filtrar = ({ nivelId, categoriaId, subcategoriaId }) => {
    if (nivelId >= 0) {
      filtroNivel = nivelId;
      filtroCategoria = 0;
      filtroSubcategoria = 0;
    }
    if (categoriaId >= 0) {
      filtroCategoria = categoriaId;
      filtroSubcategoria = 0;
    }
    if (subcategoriaId >= 0) filtroSubcategoria = subcategoriaId;

    const params = `?niv=${filtroNivel}&cat=${filtroCategoria}&sub=${filtroSubcategoria}`;

    history.push({
      pathname: location.pathname,
      search: params,
    });

    montarTabela();
  };

  const montarTabela = () => {
    const tabelasFiltros = [];

    const tbNiveis = {
      titulo: 'Níveis',
      data: [],
    };

    tbNiveis.data.push([
      <div
        key={0}
        className={`${filtroNivel === 0 ? 'btn-link-selecionado' : 'btn-link'}`}
        onClick={() => filtrar({ nivelId: 0 })}
      >
        {'(Todos)'}
      </div>,
    ]);
    niveisGeral.forEach((nivel, key) => {
      tbNiveis.data.push([
        <div
          key={key}
          className={`${
            filtroNivel === nivel.id ? 'btn-link-selecionado' : 'btn-link'
          }`}
          onClick={() => filtrar({ nivelId: nivel.id })}
        >
          {nivel.nome}
        </div>,
      ]);
    });

    tabelasFiltros.push(tbNiveis);

    const tbCategorias = {
      titulo: 'Categorias',
      data: [],
    };

    tbCategorias.data.push([
      <div
        key={0}
        className={`${
          filtroCategoria === 0 ? 'btn-link-selecionado' : 'btn-link'
        }`}
        onClick={() => filtrar({ categoriaId: 0 })}
      >
        {'(Todas)'}
      </div>,
    ]);

    categoriasGeral
      .filter((cg) => cg.nivelId === filtroNivel)
      .forEach((categoria, key) => {
        tbCategorias.data.push([
          <div
            key={key}
            className={`${
              filtroCategoria === categoria.id
                ? 'btn-link-selecionado'
                : 'btn-link'
            }`}
            onClick={() => filtrar({ categoriaId: categoria.id })}
          >
            {categoria.nome}
          </div>,
        ]);
      });

    tabelasFiltros.push(tbCategorias);

    const tbSubcategorias = {
      titulo: 'Subcategorias',
      data: [],
    };

    tbSubcategorias.data.push([
      <div
        key={0}
        className={`${
          filtroSubcategoria === 0 ? 'btn-link-selecionado' : 'btn-link'
        }`}
        onClick={() => filtrar({ subcategoriaId: 0 })}
      >
        {'(Todas)'}
      </div>,
    ]);

    subcategoriasGeral
      .filter((sg) => sg.categoriaId === filtroCategoria)
      .forEach((subcategoria, key) => {
        tbSubcategorias.data.push([
          <div
            key={key}
            className={`${
              filtroSubcategoria === subcategoria.id
                ? 'btn-link-selecionado'
                : 'btn-link'
            }`}
            onClick={() => filtrar({ subcategoriaId: subcategoria.id })}
          >
            {subcategoria.nome}
          </div>,
        ]);
      });

    tabelasFiltros.push(tbSubcategorias);

    let materiaisExibir = materiaisGeral
      .filter((m) => (filtroNivel ? m.udtNivelId === filtroNivel : true))
      .filter((m) =>
        filtroCategoria ? m.categoriaId === filtroCategoria : true
      )
      .filter((m) =>
        filtroSubcategoria ? m.subcategoriaId === filtroSubcategoria : true
      );

    const tbMateriais = {
      titulo: 'Materiais - Uni-Dunê-Te',
      data: [],
    };

    materiaisExibir.forEach((material) =>
      tbMateriais.data.push([
        <b>{material.nome}</b>,
        material.udtNivelNome,
        material.categoriaNome,
        material.subcategoriaNome,
        <Visualizar id={material.id} />,
      ])
    );

    setTabelasFiltros(tabelasFiltros);
    setTabelaMateriais(tbMateriais);
    setCarregando(false);
  };

  const Visualizar = ({ id }) => {
    return (
      <Button
        round
        justIcon
        size="sm"
        color="info"
        key={'key'}
        onClick={() => history.push(`/app/view/${id}`)}
      >
        <LocalLibrary />
      </Button>
    );
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: 30 }}>
      {carregando ? (
        <Grid
          container
          style={{ marginBottom: 15, backgroundColor: 'white', padding: 10 }}
        >
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          <Grid
            container
            style={{ marginBottom: 15, backgroundColor: 'white', padding: 10 }}
          >
            {tabelasFiltros.map((tabela) =>
              tabela.data && tabela.data.length > 0 ? (
                <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                  <Table tableHead={[tabela.titulo]} tableData={tabela.data} />
                </Grid>
              ) : (
                <h5>Sem dados para exibir.</h5>
              )
            )}
          </Grid>
          {tabelaMateriais.data && tabelaMateriais.data.length > 0 ? (
            <Grid
              container
              style={{
                marginBottom: 15,
                backgroundColor: 'white',
                padding: 10,
              }}
            >
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Table
                  tableHead={[tabelaMateriais.titulo]}
                  tableData={tabelaMateriais.data}
                />
              </Grid>
            </Grid>
          ) : (
            <h5>Sem materiais para exibir.</h5>
          )}
        </Fragment>
      )}
    </Container>
  );
}
